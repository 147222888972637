import revive_payload_client_XKrKq28KUC from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@unocss+reset@0.58.4_axios@1.6.7_eslint@8.56.0_floating-vue@5.2.2_fuse.js@7.0.0_sa_avj4vzakutcii4ix3hh5it7uvy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_b61EBsSzVN from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@unocss+reset@0.58.4_axios@1.6.7_eslint@8.56.0_floating-vue@5.2.2_fuse.js@7.0.0_sa_avj4vzakutcii4ix3hh5it7uvy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_aXMyLjN2Q6 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@unocss+reset@0.58.4_axios@1.6.7_eslint@8.56.0_floating-vue@5.2.2_fuse.js@7.0.0_sa_avj4vzakutcii4ix3hh5it7uvy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_3ouAFMf2lw from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt-site-config@2.2.8_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.15_axios@1.6.7_fuse.js@7.0_xxyycbgbdao4h2bxqfjl6tcbdm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_5uhsziE1b9 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@unocss+reset@0.58.4_axios@1.6.7_eslint@8.56.0_floating-vue@5.2.2_fuse.js@7.0.0_sa_avj4vzakutcii4ix3hh5it7uvy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_b3CAPCxuTJ from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@unocss+reset@0.58.4_axios@1.6.7_eslint@8.56.0_floating-vue@5.2.2_fuse.js@7.0.0_sa_avj4vzakutcii4ix3hh5it7uvy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_8KUyLx9ELr from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.3.3_vue@3.4.15/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_BUusViOEl4 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@unocss+reset@0.58.4_axios@1.6.7_eslint@8.56.0_floating-vue@5.2.2_fuse.js@7.0.0_sa_avj4vzakutcii4ix3hh5it7uvy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/opt/atlassian/pipelines/agent/build/.nuxt/primevue-plugin.mjs";
import plugin_client_wVtETH7f4o from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt-primevue@0.2.2_vue@3.4.15/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_qJ0pzbYaqW from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.3-alpha.1_next-auth@4.24.5_vue@3.4.15/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import composition_W52uIdCZ9u from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.6_vue-router@4.2.5_vue@3.4.15/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_5WzpGcxjro from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.6_vue-router@4.2.5_vue@3.4.15/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/opt/atlassian/pipelines/agent/build/.nuxt/unocss.mjs";
import plugin_nM77iXLQHo from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@formkit+auto-animate@0.8.1/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import chunk_reload_client_ArnvFBYLJ1 from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/nuxt@3.9.3_@unocss+reset@0.58.4_axios@1.6.7_eslint@8.56.0_floating-vue@5.2.2_fuse.js@7.0.0_sa_avj4vzakutcii4ix3hh5it7uvy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_9RNjyLX3El from "/opt/atlassian/pipelines/agent/build/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import gtm_cXsUZobmSF from "/opt/atlassian/pipelines/agent/build/plugins/gtm.ts";
import sentry_client_shVUlIjFLk from "/opt/atlassian/pipelines/agent/build/plugins/sentry.client.ts";
import vue_social_sharing_ZALq4hYFAx from "/opt/atlassian/pipelines/agent/build/plugins/vue-social-sharing.ts";
import vue_toastification_gZJw6dQQf2 from "/opt/atlassian/pipelines/agent/build/plugins/vue-toastification.ts";
export default [
  revive_payload_client_XKrKq28KUC,
  unhead_b61EBsSzVN,
  router_aXMyLjN2Q6,
  _0_siteConfig_3ouAFMf2lw,
  payload_client_5uhsziE1b9,
  check_outdated_build_client_b3CAPCxuTJ,
  plugin_vue3_8KUyLx9ELr,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BUusViOEl4,
  primevue_plugin_egKpok8Auk,
  plugin_client_wVtETH7f4o,
  plugin_qJ0pzbYaqW,
  composition_W52uIdCZ9u,
  i18n_5WzpGcxjro,
  unocss_MzCDxu9LMj,
  plugin_nM77iXLQHo,
  chunk_reload_client_ArnvFBYLJ1,
  plugin_9RNjyLX3El,
  gtm_cXsUZobmSF,
  sentry_client_shVUlIjFLk,
  vue_social_sharing_ZALq4hYFAx,
  vue_toastification_gZJw6dQQf2
]